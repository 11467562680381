<template>
  <div id="agencies-form-create">
    
    
    <b-form>
      <b-row>
        <b-col xl="12" sm="12">
          <div class="divInfo" style="background-color: rgb(239, 239, 239);">
            <p class="flex">
              <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1"/>Resolución portada: 1366x768
            </p>
          </div>
          <b-form-group label="Imagen Header" label-for="logo">
          <b-form-file
              id="logo"
              type="file"
              browse-text="Formatos: jpeg - jpg" 
              accept=".jpg, .svg"
              @change="_previewLogo"
              placeholder="Selecciona una imagen" />
              <small class="text-danger">{{ logoErrors[0] }}</small>
          </b-form-group>
          <b-row class="mt-2" v-if="createForm.media_logo != undefined">
            <b-col v-if="createForm.media_logo.full_url === ''" cols="12">
                <div class="preview">
                </div>
            </b-col>
            <b-col v-else cols="12">
                <div class="container" width="300" >
                    <img class="image" :src="createForm.media_logo.full_url" style="max-width:300px">
                </div>
            </b-col> 
          </b-row>
        </b-col>         
           
        <b-col xl="6" sm="12">
            <b-form-group label="Titulo" label-for="title">
              <b-form-input id="name"
                v-model="createForm.title"
                name="name"
                :state="titleErrors.length > 0 ? false:null"
                @input="$v.createForm.title.$touch()"
                />
              <small class="text-danger">{{ titleErrors[0] }}</small>
            </b-form-group>
          </b-col>

          <b-col xl="6" sm="12">
            <b-form-group label="Subtitulo" label-for="subtitle">
              <b-form-input id="subtitle"
                v-model="createForm.subtitle"
                name="subtitle"
                
                @input="$v.createForm.subtitle.$touch()"
                />
              
            </b-form-group>
          </b-col>
       
        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Cuerpo Header</p>
            <ckeditor id="editor1" :editor="editor" v-model="createForm.body" :config="editorConfig"
            :state="bodyErrors.length > 0 ? false:null"
            @input="$v.createForm.body.$touch()"
            ></ckeditor>
            <small class="text-danger">{{ bodyErrors[0] }}</small>
          </div>
        </b-col>  
        <b-col xl="12" sm="12">
          <div class="divInfo" style="background-color: rgb(239, 239, 239);">
            <p class="flex">
              <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1"/>Resolución portada: 1024x768
            </p>
          </div>
            <b-form-group label="Imagen cuerpo" label-for="Imagen cuerpo">
            <b-form-file
              id="cover_page"
              type="file"
              browse-text="Formatos: jpeg - jpg - png" 
              accept=".jpg, .png, .svg"
              placeholder="Selecciona una imagen" 
              @change="_previewPortada"
              />
            </b-form-group>
            <b-row class="mt-2" v-if="createForm.media_coverpage != undefined">
              <b-col v-if="createForm.media_coverpage.full_url === ''" cols="12">
                  <div class="preview">
                      <!-- <h5><strong>1366x768</strong></h5> -->
                  </div>
              </b-col>
              <b-col v-else cols="12">
                  <div class="container" width="300">
                      <img class="image" :src="createForm.media_coverpage.full_url" style="max-width:300px">
                  </div>
              </b-col> 
            </b-row>
          </b-col> 
 
        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Cuerpo footer</p>
            <ckeditor id="editor2" :editor="editor" v-model="createForm.body_footer" :config="editorConfig"
            
            @input="$v.createForm.body_footer.$touch()"
            ></ckeditor>
            
          </div>
        </b-col> 


        <b-col xl="6" sm="12">
          <b-form-group label="Fuente" label-for="fuente">
            <b-form-input id="fuente"
              v-model="createForm.source"
              name="fuente"
              
              @input="$v.createForm.source.$touch()"
              />
            
          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Fecha" label-for="fecha"> 
              <b-form-datepicker
              locale="es"
              id="date_ini" 
              placeholder="Fecha"
              v-model="createForm.date"
              :state="dateErrors.length > 0 ? false:null"
              @input="$v.createForm.date.$touch()"
              />

            <small class="text-danger">{{ dateErrors[0] }}</small>
          </b-form-group>
        </b-col>

      </b-row> 
    </b-form>

    <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()">
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_createNews()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                  Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>


  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required, numeric, email, maxLength} from 'vuelidate/lib/validators'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'  

  export default{
    name: 'NewsFormCreate',
    data() {
      return {
        overlayForm: false,
        logoAgency: '',
        cover_pageAgency: '',
        createForm: {
          name: '',
          title: '', 
          body: '',
          date: '',
          user_id: '',
          status: '',
          subtitle: '',
          source: '',
          body_footer: '',
          logo: '',
          cover_page: [], 
          media_logo: {full_url:''},
          media_coverpage: {full_url:''}, 
        },
        sheet:{
          backgroundColor:"#EFEFEF",
        },
        editor: ClassicEditor,
        editorConfig: {
          toolbar: [ 
            'heading', '|', 'bold', 'italic', '|' , "undo", "redo" , 'link', 'bulletedList', 'numberedList', 'blockQuote' ,
            'insertTable' , 'tableColumn', 'tableRow', 'mergeTableCells',
            
            ],
          height: "800px",
        },
      }
    },
    validations: {
      createForm: {
        title: {
          required,
        },
        body: {
          required,
        }, 
        date: {
          required,
        },
        logo:{
          required
        }
         
      },
    },
    computed: {
      titleErrors () {
        const errors = []
        if (!this.$v.createForm.title.$dirty) return errors
        !this.$v.createForm.title.required && errors.push('El titulo es obligatorio')
        return errors
      },
      subtitleErrors () {
        const errors = []
        if (!this.$v.createForm.subtitle.$dirty) return errors
        !this.$v.createForm.subtitle.required && errors.push('El subtitulo es obligatorio')
        return errors
      },
      bodyErrors () {
        const errors = []
        if (!this.$v.createForm.body.$dirty) return errors
        !this.$v.createForm.body.required && errors.push('El cuerpo es obligatorio')
        return errors
      },
      
      dateErrors () {
        const errors = []
        if (!this.$v.createForm.date.$dirty) return errors
        !this.$v.createForm.date.required && errors.push('La fecha es obligatoria')
        return errors
      },
      logoErrors() {
        const errors = []
        if (!this.$v.createForm.logo.$dirty) return errors
        !this.$v.createForm.logo.required && errors.push('La imagen del header es obligatoria')
        return errors
      },
       
    },
    methods: {
      ...mapActions('news',['createNews']),
      async _createNews() {
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          await this.createNews(this.createForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'noticia registrada con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.createForm =  {
              name:'',
              title:'',
              body:'',
              date:'',
              user_id:'',
              status:'',
              subtitle:'',
              source:'',
              body_footer:'',
              logo:'',
              cover_page:'',
              logo:'',
              logo: [],
              cover_page: [],
            }
            this.$v.createForm.$reset()
            this.$router.push({ name: 'news'})
            }).catch(err => {
              console.log("err");
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Problemas al registrar la noticia',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
                this.overlayForm = false
            })
        }
      }, 
      _onHidden() {
        if(this.$refs.button != null){
          this.$refs.button.focus()
        }
      },
      _previewLogo(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.createForm.media_logo.full_url = URL.createObjectURL(event.target.files[0])
            this.createForm.logo = event.target.files[0]
          }else{
            document.getElementById("logo").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
        }
      },
      _previewPortada(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.createForm.media_coverpage.full_url = URL.createObjectURL(event.target.files[0])
            this.createForm.cover_page = event.target.files[0]
          }else{
            document.getElementById("cover_page").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
        }
      },
    },
  }
</script>

<style>
.ckeditor {
      height: 400px;
      width: 100%;
  }
  .ck-content {
      height:350px;
  }
  .margin-bottom-20{
    margin-bottom:30px ;
  }
  .divInfo {
        border-radius: 10px
    }
</style>